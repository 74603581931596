import React , { useState , useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../component/header";
import Footer from "../../component/footer";
import { BtnEcovest } from "../../component/ecovestonboarding/BtnEcovest";
import axios from 'axios';


function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const [property,setproperty] = useState([]);
  const base_Url = process.env.REACT_APP_API_URL;
  useEffect(() => {
    document.title = 'Profile'; // Set the page title on component mount
    return () => {
      document.title = 'Tannit'; // Reset the page title when component unmounts
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount
  useEffect(() => {
    const fetchProperties = async () => {
        try {
          const response = await axios.get(base_Url + '/Property/Properties?start=0&pageSize=3');
          setproperty(response.data.data[0]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchProperties();
},[]);
    return (
      <div className="App">
        <Header />
        <div>
<div className="wrapper">
      <div id="carouselExampleIndicators" class="carousel slide">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
          <div className="container-fluid main-home-sec d-flex m-0">
            <div className="col-lg-7 left-back d-flex flex-row" >
              <div className='col-2 d-lg-flex d-none'></div>
              <div className="content-1stbanner d-flex flex-column my-auto gap-5 ps-lg-4 p-md-4 p-sm-3 p-3">
                <h1>Invest in <span>sustainable</span><br /> real estate</h1>
                <p>Discover a new way to invest in real estate and achieve financial growth with our innovative crowdfunding platform. Get exclusive access to promising real estate projects and become a part of the future of property investment.</p>
                <a href="/properties">Start investing <img src="assets/icons/right.svg" alt="img" /></a>
              </div>
            </div>
            <div className="col right-back" >
              <img src="assets/images/Mask group-home.png" alt="img" />
            </div>
          </div>
          </div>
          <div class="carousel-item ecovest-onboarding">
            <div className="hero-section position-relative">
                      <video
                        className="w-100 h-100 position-absolute top-0 start-0 object-fit-cover"
                        autoPlay
                        loop
                        muted
                      >
                        <source
                          src="https://cdn.pixabay.com/video/2018/10/02/18534-293472901_large.mp4"
                          type="video/mp4"
                        />
                      </video>
                      <div className="overlay position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
                      <div className="content position-relative text-white p-5 rectangle-4">
                        <img
                          className="logo-3 mb-4"
                          alt="Logo"
                          src="/assets/images/ecovestonboarding/logo-2.svg"
                        />
                        <h1 className="text-wrapper-17 display-4 fw-bold mb-4 mt-5">
                          Grow your investments, grow a greener world!
                        </h1>
                        <p className="text-wrapper-18 lead mb-5">
                          Welcome to Tannit Ecovest, the virtual forest where your green
                          investments cultivate real change. Join the movement and plant
                          virtual trees as you invest in sustainable real estate.
                        </p>
                        <div className="btn-ecovest-wrapper">
                          <BtnEcovest
                            className="btn-ecovest-instance"
                            stateProp="one-default"
                            text="Become an Eco-Investor. Plant Your Tree Today!"
                          />
                        </div>
                      </div>
                    </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      <div className="bg-new">
      <div className="container-fluid main-home-sec2 d-flex flex-lg-row flex-md-column flex-column">
        <div className="col-lg-6 left-1 ps-lg-0 p-md-4 p-sm-3 p-3" >
          
          <div className="content-2ndbanner">
            <h5>Our values</h5>
            <h2>Investing in real estate<br /> through our platform is a<br /> game-changer.</h2>
            <p>Discover a new way to invest in real estate and achieve financial<br /> growth with our innovative crowdfunding platform. Get exclusive<br /> access to promising real estate projects and become a part of the<br /> future of property investment.</p>
            <a className="d-lg-flex d-md-flex d-none" href="/properties">Invest now</a>
          </div>

        </div>
        <div className="col right-2 p-lg-0 p-md-4 p-sm-3 p-3" >
          
          <div className="container">
            <div className="row main-row-ib">
              <div className="col-lg-6 col-md-6 col-12 values-ibox-1 align-items-lg-start align-items-md-start align-items-center text-md-start text-center">
                <img src="assets/icons/building.svg" alt="img" />
                <p>Access diverse real estate investments: residential, commercial, and sustainable properties. Choose wisely</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12 values-ibox-1 align-items-lg-start align-items-md-start align-items-center text-md-start text-center">
                <img src="assets/icons/cash.svg" alt="img" />
                <p>Generate passive income through rental properties and real estate crowdfunding.</p>
              </div>
              <div className="w-100"></div>
          
              <div className="col-lg-6 col-md-6 col-12 values-ibox-1 align-items-lg-start align-items-md-start align-items-center text-md-start text-center">
                <img src="assets/icons/leaf2.svg" alt="img" />
                <p>Invest in eco-friendly properties for a sustainable future.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12 values-ibox-1 align-items-lg-start align-items-md-start align-items-center text-md-start text-center">
                <img src="assets/icons/4th.svg" alt="img" />
                <p>Together, we are creating a greener and more sustainable future. Be part of the change today. </p>
              </div>
              <div className="content-2ndbanner px-0 pt-2">
                <a className="d-lg-none d-md-none d-sm-flex w-100 justify-content-center" href="/properties">Invest now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="fourth-row mt-5 mb-lg-5 mb-sm-1 custom-section-ind">
        <div className="container">
          <div className="row p-lg-0 p-md-4 p-sm-3 p-3 flex-lg-row flex-md-column flex-column-reverse">
            <div className="col-lg-6 col-sm-12 pb-5">
              <img width="100%" src="assets/images/3rd banner.png" alt="img" />
              <button type="button" className="btn btn-primary d-lg-none d-md-none d-sm-flex w-100 mt-3">Discover</button>
            </div>
            <div className="col-lg-6 col-sm-12 third-left text-md-start text-center">
              <h5 className="small-hd">Our engagement</h5>
              <h2 className="custom-main-hd mb-lg-5 mb-sm-1">Build sustainable passive income.</h2>
              <p className="small-main-p">Investing in eco-friendly properties is one of the strategies that can provide profitable returns on investment. Ecological or green properties are designed and built with sustainable features that minimize their environmental impact.
              </p>
              <p className="small-main-p">Investing in environmentally friendly properties not only offers the potential for a profitable return on investment but also contributes to a more sustainable future by reducing carbon footprint and promoting environmentally friendly practices.</p>
              <button type="button" className="btn btn-primary d-lg-flex d-md-flex d-none">Discover</button>
            </div>
          </div>
        </div>
      </div>
      <div className="five-col mt-5 mb-lg-5 mb-sm-1 custom-card-sec p-lg-0 p-md-4 p-sm-3 p-3">
        <div className="container">
          <h5 className="small-hd">How it works</h5>
          <h2 className="custom-main-hd mb-lg-5 mb-sm-1">The processes to acquire a property on Tannit</h2>
          <div className="row property-row row property-row d-flex flex-lg-row flex-md-row flex-column justify-content-center gap-0">
            <div className="item1 col-lg-4 col-md-12 col-12 mb-5">
              <div className='property-card d-flex'>
                <img src="assets/icons/scrh.svg" alt="img" />
                <h3>Consult and select a property</h3>
                <p>Consequat curae neque amet auctor, sed fames sem tristique, nullam nisi maecenas.</p>
                <div className="cd-img">
                  <img src="assets/images/home-card1.png" alt="img" />
                </div>
              </div>
            </div>
            <div className="item2 col-lg-4 col-md-12 col-12 mb-5">
              <div className='property-card d-flex h-100'>
                <img src="assets/icons/$.svg" alt="img" />
                <h3>Put Tannit to buy a good</h3>
                <p>Consequat curae neque amet auctor, sed fames sem tristique, nullam nisi maecenas.</p>
                <div className="cd-img">
                  <img src="assets/images/home-card2.png" alt="img" />
                </div>
                <p className="note-p">Note: You will have 30 days before your investment is approved by our team.</p>
              </div>
            </div>
            <div className="item3 col-lg-4 col-md-12 col-12 mb-lg-5 mb-0">
              <div className='property-card d-flex h-100'>
                <img src="assets/icons/3rd.svg" alt="img" />
                <h3>Track investment rates on your<br /> dashboard</h3>
                <p>Consequat curae neque amet auctor, sed fames sem tristique, nullam nisi maecenas.</p>
                <div className="cd-img">
                  <img src="assets/images/home-card3.png" alt="img" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      
      
      <div className="six-col mt-5 mb-lg-5 mb-sm-1 custom-card-sec ">
        <div className="container p-lg-0 p-md-4 p-sm-3 p-3">
          <h5 className="small-hd">Ecological properties</h5>
          <h2 className="custom-main-hd mb-lg-5 mb-sm-1">The environment at the heart of TANNIT</h2>
          <div className="row six-img-box-row d-flex flex-lg-row flex-md-row flex-column justify-content-center">

            <div className="col six-col-img-box d-flex">
              <img src="assets/images/6.png" alt="img" />
              <h3>Sustainability</h3>
              <p>We integrate sustainable practices into all of our operations, prioritizing renewable energy, waste reduction, recycling, and responsible consumption </p>
            </div>

            <div className="col six-col-img-box d-flex">
              <img src="assets/images/7.png" alt="img" />
              <h3>Electricity</h3>
              <p>Our properties are committed to selecting energy sources that have a positive impact on reducing greenhouse gas emissions and minimize our impact on climate change.</p>
            </div>

            <div className="col six-col-img-box d-flex">
              <img src="assets/images/8.png" alt="img" />
              <h3>Save water</h3>
              <p>Our selection of properties are using water wisely, so we can ease the pressure on our wetlands and rivers in these difficult times.</p>
            </div>
          
          </div>
        </div>
      </div>
      <div className="container-fluid home-prop-cards">
        <div className="text-center">
        <h5 className="small-hd">Properties</h5>
        <h2 className="custom-main-hd mb-lg-5 mb-sm-1">Discover our featured listings</h2>
      </div>
        <div className="g-4 mb-5 d-lg-flex d-md-none d-none flex-lg-row flex-column justify-content-center gap-4">
        {
        property.map((item,key) => ( 
            <div key={key} className="col p-lg-0 p-md-4 p-sm-3 p-3">
                <div className="card h-100">
                    <div id={'image' + item.propertyId} className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            {item.images.map((image, imageIndex) => (
                                <button
                                    key={imageIndex}
                                    type="button"
                                    data-bs-target={'#image' + item.propertyId}
                                    data-bs-slide-to={imageIndex}
                                    className={imageIndex === 0 ? "active" : ""}
                                    aria-current={imageIndex === 0 ? "true" : "false"}
                                    aria-label={'Slide ' + image.imageId}>
                                </button>
                            ))}
                        </div>
                        <div className="carousel-inner">
                            {item.images.map((image, imageIndex) => (
                                <div
                                    key={imageIndex}
                                    className={`carousel-item ${imageIndex === 0 ? "active" : ""}`}>
                                    {image.type == 'image' ? <img src={image.url} className="d-block w-100 propertyImage" style={{'border-radius':'16px 16px 0px 0px'}} alt="..." />
                                     : <video className="d-block w-100 propertyImage" controls style={{'border-radius':'16px 16px 0px 0px'}}>
                                     <source src={image.url} type="video/mp4" /></video>}
                                    
                                    <button className="image-btn">
                                        <img src="assets/icons/leaf.svg" alt="" />Eco Friendly
                                    </button>
                                    <button className="image-btn-4">
                                        <img src="assets/icons/hleaf.svg" alt="" />125 <img src="assets/icons/up.svg " alt="" />0.2%
                                    </button>
                                </div>
                            ))}
                        </div>
                </div>



                    <div className="card-body">
                        <div className="2btn-grp">
                        {item.tags.map((tag, tagIndex) => (
                            <div key={tagIndex} className={(tag.tagId != 16 && tag.tagId != 17) ? 'd-none' : 'tag'}>
                                {(tag.tagId == 16 || tag.tagId == 17) ? <button className="global-ct">{tag.label}</button> : <div className='d-none'></div>}
                            </div>
                        ))}
                    </div>
                        <h5 className="p-card-title">{item.propertyName}</h5>
                        <div className='d-flex align-items-center mb-3'>
                        <img src="assets/icons/gb 1.svg" alt="" className='me-2' />
                        <p className="card-text">
                            {item.address.streetAddress + ', ' + item.address.city + ' ' + item.address.postalCode}
                        </p>
                        </div>
                        <div className='d-flex'>
                        {item.tags.map((tag, tagIndex) => (
                            <div key={tagIndex} className={(tag.tagId < 11 && tag.tagId > 15) ? 'd-none' : 'tag'}>
                                {(tag.tagId >= 11 && tag.tagId <= 15) ? <button className="inline-svg-icons-btn1 me-2"><i className={tag.fontAwesomeId}></i></button> : <div className='d-none'></div>}
                            </div>
                        ))}
                        </div>
                        <div>
                            <div className="card-inner">
                                <div className="amount">{(item.price / 1000) + 'K £'}</div>
                                <div className="remain"> <img src="assets/icons/Tannit coin.svg" alt="" />{((item.price /10) - item.tannitInvested) + ' remaining'}
                                </div>

                            </div>
                            <div className="card-inner2">
                                <div className="amount-text">{'Converted to: ' + (item.price /10) + ' Tannits'}</div>
                                <div className="investor"> <img src="assets/icons/Icon2.svg" alt="" />{item.numberOfInvestors + ' Investors'}</div>

                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{'width': ((((item.price / 10) - ((item.price /10) - item.tannitInvested)) / (item.price / 10)) * 100) + '%'}} aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div className="card-inner3 my-4 d-flex flex-direction-column gap-2">
                            {item.tags.map((tag, tagIndex) => (
                            <div key={tagIndex} className={(tag.tagId != 4 && tag.tagId != 6) ? 'd-none' : 'tag'}>
                                {tag.tagId == 4 ? 
                                    <div className="profit d-flex flex-direction-column gap-1 w-100">
                                        <span>Profitability</span> 
                                        <i class={tag.fontAwesomeId}></i>
                                        <p>{tag.decimalValue + '%'}</p>
                                    </div>
                                 : <div className='d-none'></div>}
                                {tag.tagId == 6 ? 
                                    <div className="returned d-flex flex-direction-column gap-1 w-100">
                                        <span>Returned</span>
                                        <i class={tag.fontAwesomeId}></i>
                                        <p>{tag.decimalValue + '%'}</p>
                                    </div>
                                 : <div className='d-none'></div>}
                            </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ))
        }
        </div>
        <div className="g-4 mb-5 d-lg-none d-md-flex d-sm-flex flex-lg-row flex-column justify-content-center gap-4">
          <Slider {...settings}>
            {property.map((item, key) => (
              <div key={key} className="col p-lg-0 p-md-4 p-sm-3 p-3">
                <div className="card h-100">
                  <div id={'image' + item.propertyId} className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                      {item.images.map((image, imageIndex) => (
                        <button
                          key={imageIndex}
                          type="button"
                          data-bs-target={'#image' + item.propertyId}
                          data-bs-slide-to={imageIndex}
                          className={imageIndex === 0 ? "active" : ""}
                          aria-current={imageIndex === 0 ? "true" : "false"}
                          aria-label={'Slide ' + image.imageId}>
                        </button>
                      ))}
                    </div>
                    <div className="carousel-inner">
                      {item.images.map((image, imageIndex) => (
                        <div
                          key={imageIndex}
                          className={`carousel-item ${imageIndex === 0 ? "active" : ""}`}>
                          {image.type === 'image' ? 
                            <img src={image.url} className="d-block w-100 propertyImage" style={{ borderRadius: '16px 16px 0px 0px' }} alt="..." />
                            : 
                            <video className="d-block w-100 propertyImage" controls style={{ borderRadius: '16px 16px 0px 0px' }}>
                              <source src={image.url} type="video/mp4" />
                            </video>}
                          
                          <button className="image-btn">
                            <img src="assets/icons/leaf.svg" alt="" />Eco Friendly
                          </button>
                          <button className="image-btn-4">
                            <img src="assets/icons/hleaf.svg" alt="" />125 <img src="assets/icons/up.svg " alt="" />0.2%
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="2btn-grp">
                      {item.tags.map((tag, tagIndex) => (
                        <div key={tagIndex} className={(tag.tagId !== 16 && tag.tagId !== 17) ? 'd-none' : 'tag'}>
                          {(tag.tagId === 16 || tag.tagId === 17) ? <button className="global-ct">{tag.label}</button> : <div className='d-none'></div>}
                        </div>
                      ))}
                    </div>
                    <h5 className="p-card-title">{item.propertyName}</h5>
                    <div className='d-flex align-items-center mb-3'>
                      <img src="assets/icons/gb 1.svg" alt="" className='me-2' />
                      <p className="card-text">
                        {item.address.streetAddress + ', ' + item.address.city + ' ' + item.address.postalCode}
                      </p>
                    </div>
                    <div className='d-flex'>
                      {item.tags.map((tag, tagIndex) => (
                        <div key={tagIndex} className={(tag.tagId < 11 && tag.tagId > 15) ? 'd-none' : 'tag'}>
                          {(tag.tagId >= 11 && tag.tagId <= 15) ? <button className="inline-svg-icons-btn1 me-2"><i className={tag.fontAwesomeId}></i></button> : <div className='d-none'></div>}
                        </div>
                      ))}
                    </div>
                    <div>
                      <div className="card-inner">
                        <div className="amount">{(item.price / 1000) + 'K £'}</div>
                        <div className="remain"> <img src="assets/icons/Tannit coin.svg" alt="" />{((item.price /10) - item.tannitInvested) + ' remaining'}
                        </div>
                      </div>
                      <div className="card-inner2">
                        <div className="amount-text">{'Converted to: ' + (item.price /10) + ' Tannits'}</div>
                        <div className="investor"> <img src="assets/icons/Icon2.svg" alt="" />{item.numberOfInvestors + ' Investors'}</div>
                      </div>
                      <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{ width: ((((item.price / 10) - ((item.price /10) - item.tannitInvested)) / (item.price / 10)) * 100) + '%' }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div className="card-inner3 my-4 d-flex flex-direction-column gap-2">
                        {item.tags.map((tag, tagIndex) => (
                          <div key={tagIndex} className={(tag.tagId !== 4 && tag.tagId !== 6) ? 'd-none' : 'tag'}>
                            {tag.tagId === 4 ? 
                              <div className="profit d-flex flex-direction-column gap-1 w-100">
                                <span>Profitability</span> 
                                <i className={tag.fontAwesomeId}></i>
                                <p>{tag.decimalValue + '%'}</p>
                              </div>
                            : <div className='d-none'></div>}
                            {tag.tagId === 6 ? 
                              <div className="returned d-flex flex-direction-column gap-1 w-100">
                                <span>Returned</span>
                                <i className={tag.fontAwesomeId}></i>
                                <p>{tag.decimalValue + '%'}</p>
                              </div>
                            : <div className='d-none'></div>}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      <div className="inv-btn-con d-flex justify-content-center">
        <a href="/properties" className="inv-btn">Start investing <img src="assets/icons/right.svg" alt="img" /></a>
      </div>
</div>
<div className="background-grad">
<div className="container-fluid eight-cont ">
  <div className="row eight-img-box-row p-lg-5 p-md-4 p-sm-3 p-3">

    <div className="col six-col-img-box2 d-flex">
    
      <h3>5M+</h3>
      <p>Access diverse real estate investments: residential, commercial, and sustainable properties. </p>
    </div>

    <div className="col six-col-img-box2 d-flex">
      
      <h3>92%</h3>
      <p>Access diverse real estate investments: residential, commercial, and sustainable properties.</p>
    </div>

    <div className="col six-col-img-box2 d-flex">
  
      <h3>500+</h3>
      <p>Average Award we have got all
        Access diverse real estate investments.</p>
    </div>
  
  
</div>
</div>
</div>
<div className="container-fluid faq-sec-home">
  <div className="text-center faq-text">
    <h5 className="small-hd">FAQ</h5>
    <h2 className="custom-main-hd mb-lg-5 mb-sm-1">Quick answers to questions you may have.</h2>
    <p>Can't find what you're looking for? Check out our full documentation.</p>
  </div>
  <div className="container custom-section-ind">
    <div className="row p-lg-0 p-md-4 p-sm-3 p-3">
      <div className="col-lg-6 col-sm-12">
        <div className="row p-3 properties-list-2 rounded-3 mb-3">
          <div className="col-lg-2 col-sm-12 ">
            <img width="60" height="60" src="assets/icons/1st.svg" alt="img" />
          </div>
          <div className="col-lg-10 col-sm-12 p-0">
            <h3 className="first-left-hd">How can I invest in real estate through this platform?</h3>
            <p className="first-left-para">Our platform simplifies real estate investing. You can browse properties, review details, and invest securely with a few clicks.</p>
          </div>
        </div>
        <div className="row p-3 properties-list-2 rounded-3 mb-3">
          <div className="col-lg-2 col-sm-12">
            <img width="60" height="60" src="assets/icons/2nd.svg" alt="img" />
          </div>
          <div className="col-lg-10 col-sm-12 p-0">
            <h3 className="first-left-hd">Is there a minimum investment amount required to get started?</h3>
            <p className="first-left-para">Minimum investment amounts vary by property. We have options for different budgets</p>
          </div>
        </div>
        <div className="row p-3 properties-list-2 rounded-3 mb-3">
          <div className="col-lg-2 col-sm-12">
            <img width="60" height="60" src="assets/icons/3rd2.svg" alt="img" />
          </div>
          <div className="col-lg-10 col-sm-12 p-0">
            <h3 className="first-left-hd">Can I track the performance of my investments?</h3>
            <p className="first-left-para">Track your investments and receive regular updates on our platform's dashboard.</p>
          </div>
        </div>
        </div>
        
      <div className="col-lg-6 col-sm-12 d-flex flex-direction-column ">
        <div className="row p-3 properties-list-2 rounded-3 mb-3">
            <div className="col-lg-2 col-sm-12 ">
              <img width="60" height="60" src="assets/icons/4rt.svg" alt="img" />
            </div>
            <div className="col-lg-10 col-sm-12 p-0">
              <h3 className="first-left-hd">What types of real estate investments are available?</h3>
              <p className="first-left-para">We offer diverse real estate investments: residential, commercial, and development projects.</p>
            </div>
          </div>
          <div className="row p-3 properties-list-2 rounded-3 mb-3">
            <div className="col-lg-2 col-sm-12">
              <img width="60" height="60" src="assets/icons/5th.svg" alt="img" />
            </div>
            <div className="col-lg-10 col-sm-12 p-0">
              <h3 className="first-left-hd">How to ensure security and transparency of investment? </h3>
              <p className="first-left-para">We prioritize security and transparency. We conduct due diligence and provide comprehensive details about each opportunity.</p>
            </div>
          </div>
          <div className="row p-3 properties-list-2 rounded-3 mb-3">
            <div className="col-lg-2 col-sm-12">
              <img width="60" height="60" src="assets/icons/6th.svg" alt="img" />
            </div>
            <div className="col-lg-10 col-sm-12 p-0">
              <h3 className="first-left-hd">What fees or charges are associated with using the platform?</h3>
              <p className="first-left-para">Fees vary by investment but are transparent. We charge a percentage-based or management fee.</p>
            </div>
          </div>
          
      </div>
      <div className="inv-btn-con d-flex justify-content-center">
        <a href="/faq" className="inv-btn2">FAQ Page <img src="assets/icons/exit.svg" alt="img" /></a>
      </div>      
    
  </div>
</div>
</div>
<div className="container last-faq-sec">
  <div className="p-lg-0 p-md-4 p-sm-3 p-3">
    <div className="col-lg-12 col-sm-12 d-flex flex-column ">
      <div className="p-3 properties-list-faq rounded-3 mb-3 d-flex flex-lg-row flex-column">
          <div className="col-lg-1 col-sm-12 ">
            <img width="32" height="32" src="assets/icons/headphones.svg" alt="img" />
          </div>
          <div className="col-lg-8 col-sm-12 p-0">
            <h3 className="first-left-hd">Still have questions?</h3>
            <p className="first-left-para">Can’t find the answer you’re looking for? please chat to our frieldy team.</p>
          </div>
          <div className="col-lg-3 col-sm-12 p-0 d-flex justify-content-start">
            <button className="faql-btn">Get in touch</button>
          </div>
        </div>
        </div>
  </div>
</div>      
</div>
</div>
<Footer />
      </div>
    );
  }
  
  export default Home;
  