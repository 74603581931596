import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';
import './styletwo.css';
import './stylethree.css';
import './CircularProgressBar.css';
import Author from './pages/authanticationpages/author';
import Services from './pages/landingpages/services';
import Blog from './pages/landingpages/blog';
import Faq from './pages/landingpages/faq';
import Signup from './pages/authanticationpages/signup';
import Verify from './pages/authanticationpages/verify';
import Claim from './pages/authanticationpages/claim';
import Success from './pages/authanticationpages/success';
import Home from './pages/landingpages/home';
import Contact from './pages/landingpages/contact';
import Dashboard from './pages/dashboardpages/dashboard';
import Profile from './pages/dashboardpages/profile';
import Propertydetail from './pages/dashboardpages/propertydetail';
import PropertydetailSale from './pages/dashboardpages/propertydetailsSale'
import Properties from './pages/dashboardpages/properties';
import Myinvestment from './pages/dashboardpages/myinvestment';
import Mymarketplace from './pages/dashboardpages/mymarketplace';
import Wallet from './pages/dashboardpages/wallet';
import TopupSuccess from './pages/dashboardpages/topupSuccess';
import BuyPropertySuccess from './pages/dashboardpages/buyPropertySuccess';
import TopupFailer from './pages/dashboardpages/topupFailer';
import PurchaseFailer from './pages/dashboardpages/purchaseFailer.js';
import Wallettwo from './pages/dashboardpages/wallettwo';
import Addmoney from './pages/dashboardpages/addmoney';
import IbForm from './pages/dashboardpages/ibform';
import BuyProperty from './pages/dashboardpages/buyproperty';
import Marketplace from './pages/dashboardpages/marketplacetwo';
import Marketplacetwo from './pages/dashboardpages/marketplacetwo';
import Purchaseconfirm from './pages/dashboardpages/purchaseconfirm';
import PurchaseSuccess from './pages/dashboardpages/purchaseSuccess';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userService from './userService';
import Callback from './callback';
import CallbackLogout from './callbackLogout';
import PrivacyPolicy from './pages/landingpages/privacyPolicy';
import TermsOfUse from './pages/landingpages/termsOfUse';
import Sellingshare from './pages/dashboardpages/sellingshare';

import EcovestWelcome from './pages/ecovestwelcomepages/WelcomeScreen';
import EcovestPlantingTree from './pages/ecovestplantingtreepages/PlantingTreeScreen';
import EcovestEarnXp from './pages/ecovestearnxppages/EarnXp';
import EcovestOnboarding from './pages/ecovestonboardingpages/EcovestOnboarding';


function App() {
  const [percentageUser, setPercentageUser] = useState(null);
  const [error, setError] = useState(null);
  const base_Url = process.env.REACT_APP_API_URL;
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const user = useSelector(state => state.auth.user);
  const { id } = user.claims || {};
  const investorId = id && id.toString();
  const handleLogout = () => {
    userService.signoutRedirect();
  };

  if (error) {
    console.log(error.message);
  }
  const fetchPercentage = async () => {
    try {
      const response = await axios.get(`${base_Url}/Progress/${investorId}`);
      setPercentageUser(!response.data.data[0]);
    } catch (error) {
      setError(error);
    }
  };
  fetchPercentage();
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/faq" element={<Faq />} />
          <Route path="/services" element={<Services />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/author" element={<Author />} />
          <Route path="/propertydetail/:propertyId" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Propertydetail />} />
          <Route path="/properties" element={!isAuthenticated ? <Navigate to="/login" /> : <Properties />} />
          <Route path="/myinvestment" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Myinvestment /> } />
          <Route path="/mymarketplace" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Mymarketplace /> } />
          <Route path="/signup" element={<Signup />} />
          <Route path="/claim" element={<Claim />} />
          <Route path="/success" element={<Success />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/dashboard" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Dashboard />} />          
          <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}/>
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/marketplace" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Marketplacetwo />} />
          <Route path="/marketplacetwo" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Marketplacetwo />} />
          <Route path="/wallet-two" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Wallettwo />}/>
          <Route path="/topupSuccess" element={<TopupSuccess /> } />
          <Route path="/buyPropertySuccess" element={<BuyPropertySuccess /> } />
          <Route path="/purchaseSuccess" element={isAuthenticated ? <PurchaseSuccess /> : <Navigate to="/login" />} />
          <Route path="/purchaseconfirm" element={isAuthenticated ? <Purchaseconfirm /> : <Navigate to="/login" />} />
          <Route path="/topupFailer" element={<TopupFailer /> } />
          <Route path="/purchaseFailer" element={<PurchaseFailer /> } />
          <Route path="/addmoney" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <Addmoney /> }/>
          <Route path="/ibform" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <IbForm /> }/>
          <Route path="/buyproperty" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <BuyProperty /> }/>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<LogoutButton onLogout={handleLogout} />} />
          <Route path="/callback" element={<Callback />} />
          <Route path="/callbackLogout" element={<CallbackLogout />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse  />} />
          <Route path="/selling-share" element={<Sellingshare  />} />
          <Route path="/property-details-sale/:propertyId" element={!isAuthenticated ? <Navigate to="/login" /> : percentageUser ? <Navigate to="/profile" /> : <PropertydetailSale />} />

          <Route path="/Ecovest/Welcome" element={isAuthenticated ? <EcovestWelcome  /> : <Navigate to="/login" />} />
          <Route path="/Ecovest/PlantingTree" element={isAuthenticated ? <EcovestPlantingTree  /> : <Navigate to="/login" />} />
          <Route path="/Ecovest/EarnXp" element={isAuthenticated ? <EcovestEarnXp  /> : <Navigate to="/login" />} />
          <Route path="/Ecovest/Onboarding" element={<EcovestOnboarding  />} />
        </Routes>
      </Router>
    </div>
  );
}

const Login = () => {
  React.useEffect(() => {
    userService.signinRedirect();
  }, []);

  return null;
};

const LogoutButton = ({ onLogout }) => {
  React.useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <Navigate to="/" />;
};

export default App;
