import React, { useState } from "react";
import axios from "axios";

const PaymentRequestForm = () => {
    const [userId, setUserId] = useState("");
    const [amount, setAmount] = useState(0);
    const [reference, setReference] = useState("");
    const [status, setStatus] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await axios.post("/api/request-payment", {
            userId,
            amount,
            reference,
            callbackUrl: "https://yourplatform.com/api/webhook/ib-payment",
        });

        setStatus(response.data.status);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="User ID"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
            />
            <input
                type="number"
                placeholder="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
            <input
                type="text"
                placeholder="Reference"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
            />
            <button type="submit">Request Payment</button>
            {status && <p>Status: {status}</p>}
        </form>
    );
};

export default PaymentRequestForm;