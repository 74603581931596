import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";

export const PlantingTree = ({
  available,
  stateProp,
  className,
  imageClassName,
  image = "/public/assets/images/ecovestplantingtree/image-85-1.png",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    available: available || "on",

    state: stateProp || "default",
  });

  return (
    <div
      className={`planting-tree ${state.state} ${state.available} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
    >
      <img
        className={`image ${imageClassName}`}
        alt="Image"
        src={
          state.state === "default"
            ? image
            : state.available === "off" && state.state === "hover"
              ? "/public/assets/images/ecovestplantingtree/image-85-3.png"
              : "/public/assets/images/ecovestplantingtree/image-85-5.png"
        }
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
  }

  return state;
}

PlantingTree.propTypes = {
  available: PropTypes.oneOf(["off", "on"]),
  stateProp: PropTypes.oneOf(["hover", "default"]),
  image: PropTypes.string,
};
