import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AddMoney = () => {
  const [selectedTannits, setSelectedTannits] = useState(null);
  const [customTannits, setCustomTannits] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Paypal");
  const [isProcessing, setIsProcessing] = useState(false);
  const [reference, setReference] = useState(""); // New state for reference input
  const apiUrl = process.env.REACT_APP_API_URL;
  const user = useSelector((state) => state.auth.user);
  const { id: userId } = user.claims;
  const navigate = useNavigate();

  const handleRadioChange = (value) => {
    setSelectedTannits(value);
    setCustomTannits("");
  };

  const handleCustomChange = (e) => {
    setSelectedTannits(null);
    setCustomTannits(e.target.value);
  };

  const handleReferenceChange = (e) => {
    setReference(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tannitsTopped = selectedTannits || customTannits;

    if (!tannitsTopped || tannitsTopped <= 0) {
      alert("Please enter a valid amount of Tannits");
      return;
    }

    if (paymentMethod === "IB" && !reference) {
      alert("Please enter a reference for IB payment");
      return;
    }

    const transaction = {
      TannitsTopped: parseInt(tannitsTopped, 10),
      Price: parseFloat(tannitsTopped) * 10,
      CurrencyId: 1,
      Description: "Top-up transaction",
      InvestorId: userId,
      CreatedAt: new Date().toISOString(),
      Vested: false,
    };

    setIsProcessing(true);

    try {
      if (paymentMethod === "Paypal" || paymentMethod === "Stripe") {
        const response = await axios.post(
          `${apiUrl}/Transaction/AddTopupTransaction`,
          transaction
        );
        if (response.status === 201 || response.status === 200) {
          navigate("/topupSuccess");
        } else {
          console.error("Failed to add transaction:", response.data);
          navigate("/topupFailer");
        }
      } else if (paymentMethod === "GoCardless") {
        navigate("/topupFailer");
      } else if (paymentMethod === "IB") {
        const ibResponse = await axios.post(
          `${apiUrl}/api/process-payment`,
          {
            user_id: userId,
            amount: tannitsTopped,
            reference: reference, // Pass the reference value
            payment_method: "IB",
            callback_url: `${apiUrl}/webhook/request-payment`,
          }
        );

        if (ibResponse.status === 200) {
          const transactionId = ibResponse.data.transaction_id;
          console.log("IB Payment Request Sent. Transaction ID:", transactionId);

          setTimeout(() => {
            setIsProcessing(false);
            navigate("/topupSuccess");
          }, 5000);
        } else {
          console.error("Failed to process IB payment:", ibResponse.data);
          navigate("/topupFailer");
        }
      }
    } catch (error) {
      console.error("Transaction Error:", error);
      navigate("/topupFailer");
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    document.title = "Add Money";
    return () => {
      document.title = "Tannit";
    };
  }, []);

  return (
    <div className="App">
      <section className="main-cont-comp" style={{ maxWidth: "800px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 main-comp-sec">
              <h3>Add Money to Your Pot: Boost Your Savings with Contributions</h3>
              <form onSubmit={handleSubmit}>
                <div className="col-12">
                  <div className="mr-1 row d-flex">
                    {/* Tannit Options */}
                    {[
                      { value: 120, price: "1200.00£" },
                      { value: 320, price: "3200.00£" },
                      { value: 1500, price: "15000.00£" },
                    ].map(({ value, price }) => (
                      <div
                        key={value}
                        className={`form-radio-check col ${
                          selectedTannits === value ? "selected" : ""
                        }`}
                        onClick={() => handleRadioChange(value)}
                        style={{
                          cursor: "pointer",
                          border:
                            selectedTannits === value
                              ? "2px solid #007bff"
                              : "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        <h4>
                          <span>
                            <img src="assets/icons/Tannit coin.svg" alt="" />
                          </span>
                          {value}
                        </h4>
                        <p>{price}</p>
                      </div>
                    ))}

                    {/* Custom Amount */}
                    <div className="col-12 not-sec">
                      <h5>Custom amount</h5>
                      <p>Amount of TANNIT desired</p>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <img src="assets/icons/Tannit coin.svg" alt="" />
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Number of TANNIT"
                          value={customTannits}
                          onChange={handleCustomChange}
                          min="1"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            = {customTannits * 10 || 0}£
                          </span>
                        </div>
                      </div>
                      <label className="form-label note">
                        Note: 1 TANNIT = 10£
                      </label>
                    </div>

                    {/* Payment Methods */}
                    <div className="col-12 new-radio-2">
                      <h3>Payment method</h3>
                      <div className="mr-1 row d-flex">
                        {["Stripe", "Paypal", "GoCardless", "IB"].map((method) => (
                          <div
                            key={method}
                            className={`form-radio-check2 col ${
                              paymentMethod === method ? "selected" : ""
                            }`}
                            onClick={() => setPaymentMethod(method)}
                            style={{
                              cursor: "pointer",
                              border:
                                paymentMethod === method
                                  ? "2px solid #007bff"
                                  : "1px solid #ccc",
                              padding: "10px",
                              borderRadius: "5px",
                            }}
                          >
                            <h4>
                              <span>
                                <img
                                  src={`assets/icons/${method.toLowerCase()}.svg`}
                                  alt=""
                                />
                              </span>
                              {method}
                            </h4>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Reference Input (only shown for IB payment) */}
                    {paymentMethod === "IB" && (
                      <div className="col-12 not-sec">
                        <h5>Payment Reference</h5>
                        <p>Enter your IB payment reference</p>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Payment reference"
                            value={reference}
                            onChange={handleReferenceChange}
                            required
                          />
                        </div>
                      </div>
                    )}

                    {/* Buttons */}
                    <div className="verification-btns d-flex">
                      <button
                        type="button"
                        className="btn radio-btn-1 btn-primary"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn radio-btn-2 btn-primary"
                        disabled={isProcessing}
                      >
                        {isProcessing ? "Processing..." : "Confirm"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AddMoney;